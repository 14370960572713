import React from 'react'
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
// import { Helmet } from 'react-helmet'

const UsePolicy = () => {
  return(
    <Layout>
      <SEO 
        title="Acceptable Use Policy" 
        description="Understand how our acceptable use policy governs the use of Billsby services." 
        url="https://www.billsby.com/terms/acceptable-use"
      />

      <div className="privacy default-template">
        <div className="section-tab">
          <div className="container">
            <ul className="tab-wrapper">
              <li className="tab-item"><Link to="/terms">Terms and conditions</Link></li>
              <li className="tab-item"><Link to="/privacy">Privacy policy</Link></li>
              <li className="tab-item"><Link to="/privacy/dpa">EU Data Processing Addendum</Link></li>
              <li className="tab-item"><Link to="/privacy/cookie-policy">Cookie policy</Link></li>
              <li className="tab-item"><Link to="/privacy/sub-processors">Sub-processors</Link></li>
              <li className="is-active tab-item"><Link to="/terms/acceptable-use">Acceptable use policy</Link></li>
            </ul>

            <p className="date">EFFECTIVE 1ST AUGUST 2019</p>
            <h1 className="section-heading">Acceptable use policy</h1>
          </div>
        </div>

        <div className="container default-content">
          <p>The Billsby service is operated in conjunction with a variety of third party payment service providers. In addition, we are obligated to abide by rules and regulations imposed by the credit card associations.</p>
          <p>By using the Billsby service, you must agree to the Billsby terms and conditions (“<strong>T&amp;Cs</strong>“). In addition to the <strong>T&amp;Cs</strong>, you must adhere to the following restrictions outlined in this Acceptable Use Policy.</p>
          
          <h4>By registering to use the Billsby service, you may not engage in activities that:</h4>
          <ul>
            <li>violate any law, statute, ordinance or regulation.</li>
            <li>engage in business practices promoting language or content which can be reasonably construed as racist – patently offensive or insulting to a particular group, gender or ethnicity.</li>
            <li>includes content which promotes or encourages hatred, violence, acts of terrorism, or any financial exploitation of a criminal activity.</li>
            <li>involve the violation of any copyright, trademark, right of publicity or privacy, or any other proprietary right under any jurisdiction.</li>
            <li>violate laws or regulations related to the sale of controlled substances such as; illegal drugs, tobacco products, prescription drugs or medications, steroids such as human growth hormone.</li>
            <li>violate the terms of the <a href="https://cloud.google.com/terms/aup">Google Cloud acceptable use policy</a></li>
            <li>violate the terms of the acceptable use policy of any payment gateways or integration partners you choose to integrate through Billsby</li>
            <li>and, if sending emails through Billsby, violate the terms of the <a href="https://www.mailgun.com/aup">Mailgun acceptable use policy</a></li>
            <li>and, if sending text messages through Billsby, violate the terms of the <a href="https://www.messagebird.com/en/legal/terms/#compliance">Messagebird compliance obligations</a></li>
            <li>and, if connected to Zapier, violate the terms of the <a href="https://zapier.com/platform/acceptable-use">Zapier acceptable use policy</a> or the terms of the acceptable use policy of any payment gateways or integration partners you choose to integrate through Zapier via Billsby</li>
          </ul>
          
          <h4>When sending emails and texts using Billsby, you may not:</h4>
            <ul>
              <li>Use Billsby to send spam:
                <ul>
                  <li>Bulk email is spam when it is unsolicited. Unsolicited means the recipient has not granted verifiable permission for the message to be sent. Bulk means that the message is sent as part of a larger collection of messages that have substantively similar content.</li>
                  <li>One-to-one commercial emails are spam when they violate CAN-SPAM. One-to-one commercial emails are also considered Spam if the data processed for purposes of sending that email is in scope of the General Data Protection Regulation (‘GDPR’) and you fail to provide notice to individuals and establish a lawful basis of processing.</li>
                </ul>
              </li>
              <li>Use any misleading or false names, addresses, email address, or subject line</li>
            </ul>
            
          <h4>When using Billsby, you will ensure that:</h4>
            <ul>
              <li>You cause no disruption to others. You agree not to use the Billsby service in a way that impacts the normal operation, privacy, integrity or security of another’s property.  Another’s property includes another’s account(s), domain name(s), URL(s), website(s), network(s), system(s), facilities, equipment, data, other information, or business operations. You also agree not to use the Billsby service to gain unauthorized access to, use, monitor, make an unauthorized reference to, another’s property, unless you have the appropriate express prior consent to do so.  Examples of prohibited actions include (without limitation): hacking, spoofing, denial of service, mailbombing and/or sending any email that contains or transmits any virus or propagating worm(s), or any malware, whether spyware, adware or other such file or program. You agree not to use the Billsby service in any way that causes or may cause any Billsby IP addresses, Billsby domains, or Billsby customer domains to be blacklisted.  These restrictions apply regardless of your intent and whether or not you act intentionally or unintentionally.</li>
            </ul>
            
          <h4>Proper usage of the Billsby service</h4>
          <p>You will respect the limits that apply to your use of the Billsby Service as specified on this page from time-to-time, and as defined in your plan tier. Limits may include, for example, rate limits on our API or limits on the usage of our applications.</p>
          <p><i>There are currently no limits applied automatically to all accounts, but we may advise you separately of limits that apply to your account.</i></p>
          <p>You will use the Billsby service for your internal business purposes and will not: (i) willfully tamper with the security of the Billsby service or tamper with our customer accounts; (ii) access data on the Billsby service not intended for you; (iii) log into a server or account on the Billsby service that you are not authorized to access; (iv) attempt to probe, scan or test the vulnerability of any Billsby service or to breach the security or authentication measures without proper authorization; (v) willfully render any part of the Billsby service unusable; (vi) lease, distribute, license, sell or otherwise commercially exploit the Billsby service or make the Billsby service available to a third party other than as contemplated in your subscription to the Billsby service; (vii) use the Billsby service for timesharing or service bureau purposes or otherwise for the benefit of a third party; or (viii) provide to third parties any evaluation version of the Billsby service without our prior written consent.</p>

          <h4>Billsby trademark use</h4>
          <p>Unless you have our express prior written permission, you may not use any name, logo, tagline or other mark of ours or the Billsby service, or any identifier or tag generated by the Billsby service, including without limitation: (a) as a hypertext link to any website or other location (except as provided for or enabled expressly by us); or (b) to imply identification with us as an employee, contractor, agent or other similar representative capacity. You also agree not to remove or alter any of these items as we may have provided or enabled.</p>
          
          <h4>General terms</h4>
          <p>We may immediately suspend your access to the Billsby service if you breach this AUP or don’t respond to us in a reasonable period after we’ve contacted you about a potential breach of this AUP.  We may also suspend your access as we explain in our terms and conditions and, if you breach this AUP, we may terminate your subscription agreement for cause. You acknowledge we may disclose information regarding your use of any Billsby service to satisfy any law, regulation, government request, court order, subpoena or other legal process.  If we make this type of required disclosure we will notify you, unless we are required to keep the disclosure confidential.</p>
          <p>We are not obligated to, but may choose to, remove any prohibited materials and deny access to any person who violates this AUP. We further reserve all other rights.</p>
          <p>We may update and change any part or all of this AUP. If we update or change this AUP, the updated AUP will be posted at this page.  If you have a Billsby account, we will we will let you know through an-in app notification, or by posting the revised copy on our website. If you do not have a Billsby account, we will let you know by posting the revised copy on our website. When we change this AUP, the “Effective date” date above will be updated to reflect the date of the most recent version. We encourage you to review this AUP periodically.</p>
        </div>
      </div>
    </Layout>
  )
}

export default UsePolicy
